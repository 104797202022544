import React from 'react'
import { ThankYou } from '../sections/'
import { Layout, Seo } from '../components'
import { injectIntl, useIntl } from 'gatsby-plugin-intl'

function Contact() {
    const intl = useIntl()
    const t = x => {
        return intl.formatMessage({ id: x })
    }
    return (
        <Layout>
            <Seo
                title={t('contact_seo_title')}
                description={t('contact_seo_description')}
            />
            <ThankYou />
        </Layout>
    )
}

export default injectIntl(Contact)
